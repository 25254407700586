import React from 'react';

import { Helmet } from 'react-helmet';

export type ArticleJsonLdProps = {
  title: string;
  author: string;
  description: string;
  datePublished: string;
  keywords: string;
  imageUrl: string;
};

const ArticleJsonLd = ({
  title,
  author,
  description,
  datePublished,
  keywords,
  imageUrl,
}: ArticleJsonLdProps) => {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    headline: title,
    image: imageUrl,
    editor: 'Prompt Interface',
    genre: 'Prompt Interface Tutorial',
    keywords: keywords,
    datePublished: datePublished,
    description: description,
    author: {
      '@type': 'Person',
      name: author,
    },
  };
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
    </Helmet>
  );
};

export default ArticleJsonLd;
